@use "../../styles/partials/_variables.scss" as *;
@import "../../styles/partials/_mixins.scss";

.connect-container {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 40vw;
  height: 3vw;
  margin-left: 58vw;

  &__icon {
    height: 3vw;
    width: 3vw;
    margin: 0 3vw;
    @include mobile {
      margin-top: 17.4vw;
    }
    @include mobile-b {
      margin-top: 17vw;
    }
    @include mobile-c {
      margin-top: 16.7vw;
    }
    @include tablet {
      margin-top: 15.675vw;
    }
    @include desktop {
      margin-top: 15.755vw;
    }
  }
}
