.eth-status-bar {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  // border: orange solid 2px;
  margin-top: -4vw;
  border-radius: 15px;
  margin-left: 10vw;
  &__price {
    // border: yellow solid 2px;
    font-size: 1.5vw;
    text-align: center;
    width: 20vw;
    color: rgb(35, 10, 77);
  }
  &__eth {
    // border: olive solid 2px;
    width: 13px;
    height: 15px;
  }
  &__table {
    // border: rgb(237, 14, 240) solid 2px;
  }
  &__market {
    display: none;
    border: blue solid 2px;
  }
  &__market {
    display: none;
    border: purple solid 2px;
  }
  &__nav {
    display: none;
    border: red solid 2px;
  }
}
