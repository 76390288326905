@use "../../styles/partials/_variables.scss" as *;
@import "../../styles/partials/mixins";

.NavBar {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/top-nav.png");
  align-items: center;
  justify-content: space-between;
  @include tablet {
    height: 130px;
  }
  @include desktop {
    height: 270px;
  }
}

.members-nav__top {
  width: 18vw;
  margin-left: 79vw;
  margin-top: 4.9vw;
  z-index: 6;
  position: absolute;

  @include mobile-b {
    margin-top: 4.6vw;
  }
  @include mobile-c {
    margin-top: 3.95vw;
  }
  @include tablet {
    margin-top: 3vw;
  }
  @include nft-mod {
    margin-top: 2.3vw;
  }
  @include nft-mod-b {
    margin-top: 2.2vw;
  }
  @include nft-mod-c {
    margin-top: 2.1vw;
  }
  @include desktop {
    margin-top: 2vw;
  }
}
