@use "../../styles/partials/_variables.scss" as *;
@import "../../styles/partials/mixins";

.lounge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(0, 0, 0);
  height: 150vw;
  width: 100vw;
  position: absolute;
  z-index: 105;

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5vw;
    color: rgb(255, 255, 255);
    z-index: 105;
    width: 50vw;
  }

  &__buttons {
    width: 20vw;
  }
}
.access-failure {
  color: #eb3647;
  margin-top: -0.15vw;
  font-size: 1.5vw;
  z-index: 105;
}

.toke {
  width: 15vw;
}

.pink-connect {
  width: 50vw;
}

.pink-verify {
  width: 50vw;
}

.meta {
  margin-top: -3.88vw;
  width: 4vw;
}
