body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .status {
  display: inline-block;
  background-image: url(assets/images/blue-loader.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 5vw;
  height: 4.9vw;
  text-align: center;
  position: absolute;
  margin-top: 5vw;
  margin-left: 47.4vw;
  margin-right: 0vw;
  z-index: 0;
  visibility: hidden;
  opacity: 0.7;
  transition: visibility 0s 3.1s, opacity 2.9s linear;
} */

.loader-container {
  background-image: url(assets/images/pink-loaded.gif);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100vw;
  height: 100vw;
  z-index: 100;
}
